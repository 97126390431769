import React, { useState } from 'react'
import { Markup } from 'interweave'
import { micromark } from 'micromark'
import 'bulma/css/bulma.min.css'
import ArticleProp from '../interfaces/ArticleProp'

const LoadingStatus = {
  requested: 'requested',
  loaded: 'loaded',
}

const Article: React.FunctionComponent<ArticleProp> = (props) => {
  const { markdownUrl } = props
  const [markdown, setMarkdown] = useState('Loading...')
  const [status, setStatus] = useState(LoadingStatus.requested)
  fetch(markdownUrl)
    .then(async (res) => {
      const md = await res.text()
      setMarkdown(md)
      setStatus(LoadingStatus.loaded)
    })
    // eslint-disable-next-line no-console
    .catch((err) => console.error(err))
  return (
    <div className='column content'>
      {status === LoadingStatus.loaded && (
        <Markup allowElements tagName='div' content={micromark(markdown)} />
      )}
    </div>
  )
}

export default Article

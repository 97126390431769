import React from 'react'
import 'bulma/css/bulma.min.css'
import Article from '../components/Article'
import ArticleProp from '../interfaces/ArticleProp'
import ProfileWidget from '../components/ProfileWidget'

const article: ArticleProp = {
  markdownUrl: 'markdowns/hello.md',
  title: '',
  subtitle: 'Testing',
}

const Home: React.FunctionComponent<{}> = () => (
  <div className='is-family-monospace'>
    <div className='section'>
      <div className='container is-fullhd'>
        <div className='columns is-family-code' style={{}}>
          {/* LHS Column */}
          <ProfileWidget />

          {/* RHS Column */}
          <Article
            title={article.title}
            subtitle={article.subtitle}
            markdownUrl={article.markdownUrl}
          />
        </div>
      </div>
    </div>

    <div className='section'>
      <footer className='footer'>
        <div className='content has-text-centered'>
          <p>
            This website is build with <a href='https://reactjs.org/'>ReactJS</a>,{' '}
            <a href='https://bulma.io/'>Bulma</a> and{' '}
            <a href='https://interweave.dev/'>Interweave</a>. ❤️
          </p>
          <p>
            Deployed on{' '}
            <a href='https://www.digitalocean.com/products/app-platform'>
              DigitalOcean App Platform 🦈
            </a>
          </p>
        </div>
      </footer>
    </div>
  </div>
)

export default Home

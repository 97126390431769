import React from 'react'
import { Routes, Route } from 'react-router-dom'
import './App.css'

import Home from './pages/Home'

const App: React.FunctionComponent<{}> = () => (
  <Routes>
    <Route path='/' element={<Home />} />
  </Routes>
)

export default App

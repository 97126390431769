import React from 'react'
import MyImage from '../assets/my-image.jpg'

interface ProfileWidgetProps {}

const ProfileWidget: React.FunctionComponent<ProfileWidgetProps> = () => (
  <div className='column is-3 has-text-centered' style={{}}>
    {/* The Profile Image */}
    <figure className='image is-128x128 is-inline-block block'>
      <img className='is-rounded ' src={MyImage} alt='' />
    </figure>
    {/* Name */}
    <div className='title'>Abhay N. Pai</div>
    <hr />
    <div className='subtitle'>
      Co-Founder &amp; CTO,
      <br />
      StepSetGo
    </div>
    <hr />
    <div className='subtitle'>
      Forbes 30 under 30 Asia,
      <br />
      Consumer Technology
    </div>
    <hr />
  </div>
)

export default ProfileWidget
